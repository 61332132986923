@import 'variables';

.app-tool-panel {
  // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 99;
  background-color: white;

  .app-tool-panel-breadcrumb {
    padding: 10px 24px 4px 16px;
    display: block;
  }

  app-tab-menu {
    padding: 0 0 0 16px;
  }

  .app-tool-panel-headline {
    padding: 0 24px 0px 16px;

    .app-tool-panel-title {
      font-size: 22px;
      font-weight: 700;
      color: map-get($md-primary, 500);
      text-transform: uppercase;
      white-space: nowrap;
    }

    .app-tool-panel-subtitle {
      font-size: 12px;
      font-weight: 400;
      //min-width: 250px;
      margin-right: 12px;
      text-transform: none;
      white-space: nowrap;
      color: #757575;
    }

    .app-tool-panel-avancement {
      margin-top: auto;
      font-size: 12px;
      font-weight: 400;
      > div {
        border: 1px solid #757575;
        text-overflow: ellipsis;

        border-radius: 10px;
        box-sizing: border-box;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        display: block;
        font-size: 12px;
        font-weight: 400;
        height: 20px;
        line-height: 16px;
        overflow: hidden;
        padding: 0 6px;
        min-width: 24px;
        white-space: nowrap;
        text-align: center;
      }
    }
    .app-tool-panel-step {
      background-color: #757575;
      color: white;
    }
    .app-tool-panel-step-progress {
      color: #757575;
    }
    .app-menu-margin {
      margin-right: -15px !important;
    }
    .avatar {
      background-color: yellowgreen;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      font-size: 16px;
      text-align: center;
      margin-top: auto;
      font-weight: 500;
      > div {
        padding-top: 5px;
      }
    }
  }

  .app-tool-panel-button {
    line-height: 32px !important;
  }
}
