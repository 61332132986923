/* BEGIN Regular */
@font-face {
  font-display: swap;
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FolderName, $FontName, $FontVersion, 'Regular');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FolderName, $FontName, $FontVersion, 'Regular');
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
