// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/theming';

@include mat-core();

$custom-typography: mat-typography-config(
  $subheading-2: mat-typography-level(14px, 28px, 400),
  $font-family: 'Segoe UI'
);

@include angular-material-typography($custom-typography);

$candy-app-primary: mat-palette($md-primary);
$candy-app-accent: mat-palette($md-accent, A200, A100, A400);
$candy-app-warn: mat-palette($md-warn);

$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include angular-material-theme($candy-app-theme);
/*
 *
 * Forms
 *
 */

.mat-form-field-label,
.mat-form-field-label-wrapper {
  overflow: visible !important;
}

mat-form-field.remove-infix {
  .mat-form-field-flex {
    padding-top: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 10px;
  }

  .mat-form-field-infix {
    padding: 5px 0;
  }

  .mat-form-field-underline {
    top: 1.5em !important;
  }

  display: inline-block;
  margin-bottom: -1.5em !important;

  > div {
    background-color: transparent !important;

    > div {
      background-color: white;
      border-radius: 4px;

      > div {
        border-top: 0px;
      }
    }
  }
}

mat-form-field.remove-infix.background-grey {
  > div {
    > div {
      > div {
        margin: 0 5px 0 10px;

        > span.gulp {
          font-size: 18px;
          line-height: 12px;
          vertical-align: middle;
        }
      }
    }
  }
}

mat-form-field.remove-infix.background-grey:not(:focus-within) {
  > div {
    > div {
      background-color: whitesmoke;
    }
  }
}

mat-form-field.remove-infix-max-char {
  margin-bottom: -1.25em !important;

  > div {
    background-color: transparent !important;

    > div:nth-of-type(-n + 2) {
      background-color: white;

      > div {
        border-top: 0px;
      }
    }
  }
}

mat-form-field.w50 {
  .mat-form-field-wrapper {
    width: 50px;
  }
}

mat-form-field.w60 {
  .mat-form-field-wrapper {
    width: 60px;
  }
}

mat-form-field.w70 {
  .mat-form-field-wrapper {
    width: 70px;
  }
}

mat-form-field.w80 {
  .mat-form-field-wrapper {
    width: 80px;
  }
}

mat-form-field.w90 {
  .mat-form-field-wrapper {
    width: 90px;
  }
}

mat-form-field.w100 {
  .mat-form-field-wrapper {
    width: 100px;
  }
}

mat-form-field.w110 {
  .mat-form-field-wrapper {
    width: 110px;
  }
}

mat-form-field.w120 {
  .mat-form-field-wrapper {
    width: 120px;
  }
}

mat-form-field.w130 {
  .mat-form-field-wrapper {
    width: 130px;
  }
}

mat-form-field.w150 {
  .mat-form-field-wrapper {
    width: 150px;
  }
}

mat-form-field.w180 {
  .mat-form-field-wrapper {
    width: 180px;
  }
}

mat-form-field.w200 {
  .mat-form-field-wrapper {
    width: 200px;
  }
}

mat-form-field.w220 {
  .mat-form-field-wrapper {
    width: 220px;
  }
}

mat-form-field.w250 {
  .mat-form-field-wrapper {
    width: 250px;
  }
}

mat-form-field.w300 {
  .mat-form-field-wrapper {
    width: 300px;
  }
}

mat-form-field.w350 {
  .mat-form-field-wrapper {
    width: 350px;
  }
}

mat-form-field.w400 {
  .mat-form-field-wrapper {
    width: 400px;
  }
}

mat-form-field.w450 {
  .mat-form-field-wrapper {
    width: 450px;
  }
}

mat-form-field.w500 {
  .mat-form-field-wrapper {
    width: 500px;
  }
}

mat-form-field.pl20 {
  padding-left: 20px;
}

mat-form-field.badgeC {
  .mat-form-field-infix {
    border-top-style: none !important;
  }
}

mat-form-field.autocomp {
  cursor: pointer !important;

  input {
    cursor: pointer !important;
  }
}

.mat-input-element:disabled {
  color: rgba(black, 0.8) !important;
}

.mat-select-disabled .mat-select-value {
  color: rgba(black, 0.8) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px !important;
  padding: 0 8px !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

/*
 *
 * Chips
 *
 */
.mat-chip.mat-standard-chip {
  min-height: 24px !important;
  font-weight: normal !important;
}

/*
 *
 * Icons
 *
 */

.material-icons-outlined.option {
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
}

.material-icons-outlined {
  &.green {
    color: #178e17;
  }

  &.red {
    color: #e20000;
  }

  &.blue {
    color: #2061c4;
  }

  &.grey {
    color: #535353;
  }
}

/*
 *
 * Modals
 *
 */

.mat-dialog-container {
  padding: 0px !important;
}

// Backdrop padding for navbar feature

.cdk-overlay-backdrop {
  padding-bottom: 48px;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: rgba(0, 0, 0, 0.288);
  }
}

/*---------------------------------------------------------------------------*/
// Default Material overrides

.mat-dialog-title {
  margin: 0 !important;
  background-color: map-get($md-primary, 500);
  padding: 20px;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 5px 24px !important;
}

.mat-dialog-actions {
  margin: 0 !important;
  padding: 12px 24px !important;
  float: right;
}

/*---------------------------------------------------------------------------*/
// Customs Material overrides

// fit height
.fit-dialog,
.fit-dialog .mat-tab-body-wrapper,
.fit-dialog .mat-tab-body-wrapper,
.fit-dialog .mat-tab-body-wrapper .mat-tab-body,
.fit-dialog .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}

/*---------------------------------------------------------------------------*/
// Sizes Material overrides

.mat-dialog-container {
  // To give full control to dialog-content div
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.dialog-content-no-paddings {
  .mat-dialog-content {
    padding: 0 !important;
  }
}

// passage en pleine hauteur
.custom-dialog-fullheight {
  height: 98% !important;
  max-height: 100% !important;

  .mat-dialog-content {
    min-height: calc(98vh - 56px) !important; // 56px from header

    // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .mat-dialog-container {
    min-height: 98vh !important;
  }
}

@media screen and (max-width: 600px) {
  // Equivalent to 'smarthphone'
  .custom-dialog-fullscreen-xs {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    .mat-dialog-content {
      min-height: calc(100vh - 56px) !important; // 56px from header

      // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mat-dialog-container {
      min-height: 100vh !important;
    }
  }

  .fit-dialog,
  .fit-dialog .mat-tab-body-wrapper,
  .fit-dialog .mat-tab-body-wrapper,
  .fit-dialog .mat-tab-body-wrapper .mat-tab-body,
  .fit-dialog .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
    max-height: calc(100vh - 56px) !important;
  }
}

@media screen and (max-width: 959px) {
  // Equivalent to 'tablet'
  .custom-dialog-fullscreen-sm {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    .mat-dialog-content {
      min-height: calc(100vh - 56px) !important; // 56px from header

      // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .mat-dialog-container {
      min-height: 100vh !important;
    }
  }
}

.mat-tab-header {
  border-bottom: none;
}

.mat-flat-button.mat-primary {
  color: white;
}

.mat-tab-link {
  min-width: 60px !important;
  padding: 0 !important;
  height: 40px !important;
  margin-right: 24px;
}

.rate-profile-select {
  .mat-select-trigger {
    .mat-select-value {
      text-align: right;
    }

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.company-choice {
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px 8px 4px;

  .mat-select-trigger {
    background-color: #39657b;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;

    .mat-select-arrow,
    .mat-select-value-text {
      color: #ffffff;
    }
  }
}

.menu-neuf {
  .mat-select-trigger {
    background-color: #6ab5b7;
  }
}

.menu-ancien {
  .mat-select-trigger {
    background-color: #39657b;
  }
}

.mat-option {
  .mat-pseudo-checkbox-checked {
    background-color: map-get($md-primary, 500);
  }
}

.mat-stepper-horizontal.no-margin {
  .mat-horizontal-content-container {
    margin: 0;
    padding: 0;
  }
}

.mat-horizontal-stepper-header {
  height: 32px !important;
}

.mat-step-header .mat-step-icon-selected {
  color: white;
}

app-prospect-stepper {
  .mat-horizontal-stepper-header-container {
    width: 500px;
    margin: auto;
  }
}

app-add-offer {
  .mat-dialog-actions {
    padding: 0 !important;
  }
}
