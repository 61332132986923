.main-icon {
  i {
    font-size: 32px;
  }
}

.information-icon {
  i {
    font-size: 20px;
    line-height: 20px;
  }
}

.sub-icon {
  i {
    font-size: 14px;

    &:before {
      background-color: white;
      border-radius: 100%;
    }
  }
}
