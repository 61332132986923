/*
 * Default theme (only HTML tags customization here)
 */

html,
body {
  font-display: swap;
  font-family: 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  background-color: whitesmoke;
}

a {
  color: map-get($md-primary, 500);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: map-get($md-primary, 500);
  }
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
  overflow-x: overlay;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border: 3px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track-piece {
  width: 4px;
}

.ag-theme-balham .ag-ltr .ag-cell-focus {
  border: 1px solid rgba(22, 145, 133, 1) !important;
}

.ag-residence-sheet {
  max-width: 100%;
}

::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}
