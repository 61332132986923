/* BEGIN Bold */
@font-face {
  font-display: swap;
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FolderName, $FontName, $FontVersion, 'Bold');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FolderName, $FontName, $FontVersion, 'Bold');
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
