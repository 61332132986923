// Normalize
@import '~normalize-scss/sass/normalize';

html,
body {
  font-size: 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

p {
  margin: 8px 0;
}
