.app-border {
  padding: 0 8px 8px 8px;
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

  .app-border-header-icon,
  .app-border-subheader-icon {
    width: 100%;
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;

    span {
      color: black;
      font-weight: 700;
    }
    > i {
      padding-right: 10px;
      vertical-align: bottom;
      padding-left: 5px;
      display: none;
    }
  }

  .app-border-header-icon {
    span {
      font-size: 18px;
      margin-top: 4px;
    }
  }
  .app-border-subheader-icon {
    span {
      font-size: 15px;
    }
  }

  .app-suivi {
    font-size: 0.8em;
    color: #949494;
    line-height: 1.5em;
  }
}
