@import 'variables';

.app-stratalot-libre .app-stratalot-conge .app-stratalot-vendu {
  color: white;
}

.app-stratalot-libre {
  background-color: map-get($md-primary, 500);
}

.app-stratalot-conge {
  background-color: map-get($md-accent, 500);
}

.app-stratalot-vendu {
  background-color: map-get($md-paletteGrey, 50);
}
