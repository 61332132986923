@import '@angular/material/theming';

.app-chip {
  background-color: mat-color(mat-palette($mat-indigo, 500));
  padding: 0 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  padding: 0 1rem;
  border-radius: 1rem;
  font-size: 1rem;
}
